import {
    FC,
    MutableRefObject,
    PropsWithChildren,
    RefObject,
    TouchEvent,
    WheelEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import ResizeObserver from "resize-observer-polyfill";
import classNames from "classnames";
import styles from "./scroll-component.module.sass";

interface IScrollComponentProps {
    containerClass?: string;
    contentClass?: string;
    showScrollBar?: boolean;
    animateWithTopProperty?: boolean;
    onScroll?: () => void;
    scrollTo?: number | null;
    clearScrollToPos?: () => void;
}

const ScrollComponent: FC<PropsWithChildren<IScrollComponentProps>> = ({
    containerClass,
    contentClass,
    showScrollBar,
    animateWithTopProperty,
    onScroll,
    children,
    scrollTo,
    clearScrollToPos,
}) => {
    const [top, setTop] = useState<number>(0);
    const [buttonHeight, setButtonHeight] = useState<number>(0);
    const [buttonTop, setButtonTop] = useState<number>(0);
    const [touchStartY, setTouchStartY] = useState(0);
    const containerRef: RefObject<HTMLDivElement | null> = useRef(null);
    const contentRef: RefObject<HTMLDivElement | null> = useRef(null);
    const buttonRef: RefObject<HTMLDivElement | null> = useRef(null);
    const offsetStartYRef: MutableRefObject<number> = useRef(0);
    const resizeObserver: MutableRefObject<ResizeObserver | null> = useRef(null);

    const updateScrollBar = useCallback(
        (topPosition?: number) => {
            if (contentRef.current && containerRef.current) {
                const delta: number = topPosition !== undefined ? topPosition : top;

                const scrollBarHeight: number =
                    contentRef.current && containerRef.current
                        ? (containerRef.current.offsetHeight * containerRef.current.offsetHeight) /
                          contentRef.current.offsetHeight
                        : 0;
                const maxTop: number =
                    contentRef.current.offsetHeight - containerRef.current.offsetHeight;
                let topValue: number;

                if (
                    delta < 0 ||
                    contentRef.current.offsetHeight < containerRef.current.offsetHeight
                ) {
                    topValue = 0;
                } else {
                    topValue = delta >= maxTop ? maxTop : delta;
                }

                let buttonTopValue = 0;

                if (scrollBarHeight !== 0) {
                    let dif: number =
                        contentRef.current.offsetHeight - containerRef.current.offsetHeight;
                    if (dif === 0) {
                        dif = 1;
                    }
                    buttonTopValue =
                        (topValue * (containerRef.current.offsetHeight - scrollBarHeight)) / dif;
                }

                setTop(topValue);
                setButtonHeight(scrollBarHeight);
                setButtonTop(buttonTopValue);
            }

            if (onScroll) {
                onScroll();
            }
        },
        [onScroll, top],
    );

    const onMouseWheel = (event: WheelEvent) => {
        event.stopPropagation();

        const isFirefox: boolean = navigator.userAgent.indexOf("Firefox") !== -1;
        const deltaY: number = isFirefox ? event.deltaY * 33 : event.deltaY;

        requestAnimationFrame(() => {
            updateScrollBar(top + deltaY);
        });
    };

    const onTouchStartHandler = (event: TouchEvent) => {
        setTouchStartY(event.touches[0].pageY);
    };

    const onTouchMoveHandler = (event: TouchEvent) => {
        const isFirefox: boolean = navigator.userAgent.indexOf("Firefox") !== -1;
        const deltaY = touchStartY - event.touches[0].pageY;

        requestAnimationFrame(() => {
            updateScrollBar(top + (isFirefox ? deltaY * 33 : deltaY));
        });
    };

    const onDrag = (event: any) => {
        if (containerRef.current && contentRef.current) {
            updateScrollBar(
                ((event.pageY -
                    containerRef.current.getBoundingClientRect().top -
                    offsetStartYRef.current) *
                    contentRef.current.offsetHeight) /
                    containerRef.current.offsetHeight,
            );
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const onMouseLeave = () => onDragEnd();

    const onDragEnd = () => {
        window.removeEventListener("mouseup", onDragEnd);
        window.removeEventListener("mousemove", onDrag);
        document.removeEventListener("mouseleave", onMouseLeave);
    };

    const onDragStart = (event: any) => {
        event.persist();
        event.stopPropagation();
        event.preventDefault();

        offsetStartYRef.current = event.nativeEvent.offsetY;
        document.addEventListener("mouseleave", onMouseLeave);
        window.addEventListener("mousemove", onDrag);
        window.addEventListener("mouseup", onDragEnd);
    };

    const onScrollBarClickHandler = (event: any) => {
        if (containerRef.current && contentRef.current) {
            updateScrollBar(
                ((event.pageY -
                    containerRef.current.getBoundingClientRect().top -
                    buttonHeight / 2) *
                    contentRef.current.offsetHeight) /
                    containerRef.current.offsetHeight,
            );
        }
    };

    useEffect(() => {
        const resizeObserverHandler = () => {
            updateScrollBar();
        };

        const windowResizeHandler = () => {
            updateScrollBar();
        };

        const currentContentRef = contentRef.current;
        resizeObserver.current = new ResizeObserver(resizeObserverHandler);
        if (currentContentRef) {
            resizeObserver.current.observe(currentContentRef);
        }

        window.addEventListener("resize", windowResizeHandler);

        return () => {
            if (currentContentRef) {
                resizeObserver.current?.unobserve(currentContentRef);
            }
            window.removeEventListener("resize", windowResizeHandler);
        };
    }, [top, updateScrollBar]);

    useEffect(() => {
        if (scrollTo && scrollTo !== top) {
            updateScrollBar(scrollTo - 10);
            clearScrollToPos?.();
        }
    }, [clearScrollToPos, scrollTo, top, updateScrollBar]);

    const isScrollable = Boolean(
        contentRef.current &&
            containerRef.current &&
            contentRef.current.offsetHeight > containerRef.current.offsetHeight,
    );

    return (
        <div
            className={classNames(styles.scrollbar, containerClass)}
            ref={containerRef}
            onTouchStart={onTouchStartHandler}
            onTouchMove={onTouchMoveHandler}
            onWheel={onMouseWheel}>
            <div
                className={classNames(styles.scrollableContent, contentClass, {
                    [styles.showVerticalScrollBar]: showScrollBar || isScrollable,
                })}
                ref={contentRef}
                style={
                    animateWithTopProperty
                        ? { top: `${-top}px` }
                        : { transform: `translateY(${-top}px)` }
                }>
                {children}
            </div>
            {(showScrollBar || isScrollable) && (
                <div onClick={onScrollBarClickHandler} className={styles.verticalScrollbar}>
                    <div
                        className={styles.scrollButton}
                        ref={buttonRef}
                        onMouseDown={onDragStart}
                        style={{
                            height: isScrollable ? buttonHeight : 0,
                            top: isScrollable ? buttonTop : 0,
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default ScrollComponent;
