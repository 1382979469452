import { useContext, useMemo } from "react";
import { SignupInputFieldName } from "@finbackoffice/enums";
import { SignupConfigType, SiteVerificationMethod } from "@finbackoffice/fe-core";
import { IProfileResponse } from "@finbackoffice/clientbff-client";
import { UserAccountContext, useRuntimeConfig } from "@finbackoffice/site-core";

export const usePersonalDetailsVerificationConfig = () => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const { userData } = useContext(UserAccountContext);
    const personalDetailsConfig = useMemo(
        () =>
            COMMON_SITE_CONFIGS.verification.config.find(
                (config) => config.name === SiteVerificationMethod.PersonalDetails,
            ),
        [COMMON_SITE_CONFIGS.verification.config],
    );

    const personalDetailsVerificationConfigObj = useMemo(() => {
        const result: Partial<Record<SignupInputFieldName, SignupConfigType>> = {};

        personalDetailsConfig?.config?.forEach((item) => {
            if (item.enable) {
                result[item.name] = item;
            }
        });

        return result;
    }, [personalDetailsConfig?.config]);

    const personalDetailsVerificationConfigMemo = useMemo(
        () => personalDetailsConfig?.config?.filter((field) => field.enable) || [],
        [personalDetailsConfig?.config],
    );

    const needToVerify = useMemo(() => {
        if (!userData) {
            return;
        }
        const requiredFields =
            personalDetailsVerificationConfigMemo?.filter((field) => field.required) || [];
        const blankFields = [];
        requiredFields.forEach((field) => {
            const fieldName = field.name;
            if (fieldName in userData && !userData[fieldName as keyof IProfileResponse]) {
                blankFields.push(fieldName);
            }
        });

        return !!blankFields.length;
    }, [personalDetailsVerificationConfigMemo, userData]);

    return {
        personalDetailsVerificationConfigObj,
        personalDetailsVerificationConfig: personalDetailsVerificationConfigMemo,
        needToVerify,
    };
};
