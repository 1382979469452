import { RefObject, useEffect, useRef } from "react";

export const useSelectorPositionCounter = (
    ref: RefObject<HTMLElement | null>,
    initialTopPosition: number,
) => {
    const resizeObserverRef = useRef<ResizeObserver>(undefined);

    useEffect(() => {
        const onResize = (entries: any) => {
            if (entries[0].contentRect.height >= window.innerHeight - initialTopPosition) {
                if (ref.current) {
                    ref.current.style.top = `${
                        window.innerHeight - 17 - entries[0].contentRect.height
                    }px`;
                }
            }
        };

        const currentRef = ref.current;

        if (currentRef) {
            currentRef.style.maxHeight = `${window.innerHeight - 120}px`;

            resizeObserverRef.current = new ResizeObserver((entries) => {
                // This code suppose to fix following error: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
                window.requestAnimationFrame(() => {
                    if (!Array.isArray(entries) || !entries.length) {
                        return;
                    }
                    onResize(entries);
                });
            });
            resizeObserverRef.current?.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                resizeObserverRef.current?.unobserve(currentRef);
            }
        };
    }, [initialTopPosition, ref]);
};
