import { useEffect, useRef } from "react";

export const useFreezeValue = (value: any) => {
    const ref = useRef(undefined);

    useEffect(() => {
        if (!ref.current) {
            ref.current = value;
        }
    }, [value]);

    return ref.current;
};
